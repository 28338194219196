import { FeaturePermissionDefinition } from "./types"

export type RestrictableFeature =
	| "textFormatterAiOptions"
	| "uniqueLinks"
	| "aiParsingEnabled"
	| "jobDescription"
	| "folders"
	| "fullJobDescription"
	| "placeholderTags"
	| "originalCVEditing"
	| "originalCVHeaders"

export const RESTRICTABLE_FEATURES: { [key in RestrictableFeature]: FeaturePermissionDefinition } = {
	textFormatterAiOptions: {
		label: "Text Formatter AI Options",
		releaseState: "production",
		clientAccessControlled: true,
		userAccessControlled: false,
		defaultClientAccess: true,
	},
	uniqueLinks: {
		label: "Landing Pages",
		releaseState: "production",
		clientAccessControlled: true,
		userAccessControlled: false,
	},
	aiParsingEnabled: {
		label: "AI Parsing",
		releaseState: "production",
		clientAccessControlled: true,
		userAccessControlled: false,
		defaultClientAccess: true,
	},
	jobDescription: {
		label: "Job Description",
		releaseState: "production",
		clientAccessControlled: true,
		userAccessControlled: false,
	},
	folders: { label: "Folders", releaseState: "production", clientAccessControlled: true, userAccessControlled: false },
	fullJobDescription: {
		label: "Job Info (Sift)",
		releaseState: "production",
		clientAccessControlled: true,
		userAccessControlled: false,
	},
	placeholderTags: {
		label: "Placeholder Tags",
		releaseState: "production",
		clientAccessControlled: true,
		userAccessControlled: false,
		defaultClientAccess: true,
	},
	originalCVEditing: {
		label: "Original CV Editing",
		releaseState: "production",
		clientAccessControlled: false,
		userAccessControlled: false,
	},
	originalCVHeaders: {
		label: "Original CV Headers",
		releaseState: "production",
		clientAccessControlled: false,
		userAccessControlled: false,
	},
}
